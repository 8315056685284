<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
   <!--        <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option> -->
        </el-select>
  <!--       <reseller-selector
          v-if="
            !filterReseller &&
            $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
          "
          @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
        /> -->
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="organizationGroups"
            @sort-change="sortChange"
          > 
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column 
            :label="$t('COMMON.ID')" 
            min-width="90px"
            prop="id"
            sortable="custom"
            >
    <!--           <template v-slot="{ row }">
                <img
                  v-if="row.logo"
                  :src="row.logo"
                  class="avatar rounded-circle mr-3"
                />
              </template> -->
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="220"
            />
   <!--          <el-table-column
              :label="$t('COMMON.RESELLER')"
              sortable="custom"
              min-width="220"
              v-if="
                !filterReseller &&
                $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
              "
            >
              <template v-slot="{ row }">
                <reseller :reseller="row.reseller" />
              </template>
            </el-table-column> -->
    <!--         <el-table-column
              :label="$t('COMMON.OWNER')"
              min-width="220"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
            >
              <template v-slot="{ row }">
                <span>
                  {{
                    row.owner
                      ? `${row.owner.lastname} ${row.owner.firstname} - ${row.owner.email}`
                      : null
                  }}
                </span>
              </template>
            </el-table-column> -->
            <el-table-column
              :label="$t('COMMON.DESCRIPTION')"
              prop="description"
              sortable="custom"
              min-width="220"
            />
       <!--      <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="createdAt"
              sortable="custom"
              min-width="220"
            /> -->
            <el-table-column
      :label="$t('COMMON.CREATED_AT')"
      prop="createdAt"
      sortable="custom"
      min-width="220"
    >
      <template slot-scope="scope">
        {{ scope.row.createdAt | formatDate }}
      </template>
    </el-table-column>
    <!--   <el-table-column 
            :label="$t('COMMON.ORGANIZATIONS')" 
             min-width="90px"
              prop="id"
              sortable="custom"
            >
      </el-table-column> -->
      <el-table-column
              :label="$t('COMMON.ORGANIZATIONS')"
              min-width="150px"
              prop="organizations"
              sortable="custom"
            >
            <template slot-scope="scope">
    <el-select
      v-model="selectedOrganizations[scope.row.id]"
      multiple
      collapse-tags
      placeholder="Select organizations"
    >
      <el-option
        v-for="org in organizations"
        :key="org.id"
        :label="org.name"
        :value="org.id"
      ></el-option>
    </el-select>
    <el-button @click="attachOrganizations(scope.row.id,scope.row.id)">Attach</el-button>
  </template>
      </el-table-column>
<!--       <el-table-column
        label="Organizations"
        min-width="300px"
      >
        <template slot-scope="scope">
          <div v-for="organization in scope.row.organizations" :key="organization.id">
            {{ organization.name }}
          </div>
        </template>
      </el-table-column> -->
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                 
                >
                  <a
                    type="text"
                    @click="viewOrganizationGroups(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                
                >
                  <a
                    type="text"
                    @click="editOrganizationGroups(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
             
                >
                  <a
                    type="text"
                    @click="deleteOrganizationGroups(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
  <!--       <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p> -->
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>

<script>

import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import moment from 'moment';
/* import ResellerSelector from "@/components/ResellerSelector.vue"; */



export default {
  name: "organization-groups-list-table",

  components: {
    BasePagination,
/*     ResellerSelector, */
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
/*     filterReseller: {
      type: String,
      default: null,
      description: "reseller id",
    }, */
  },

  data() {
    return {
      query: null,
      selectedRows: [],
  /*     sort: "-created_at", */
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      organizationGroups: [],
      organizations: [],
      selectedOrganizations: {},
      loading: true,
  /*     selectedReseller: null, */
    };
  },
  filters: {
    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    }
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
/* 
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    }, */
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  /*   pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    }, */
/*     filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    }, */
/*     selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    }, */
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
      console.log(this.getList(),"this.getList();");
    }, 300),
/*     filters: {
  formatDate(date) {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }
      }, */
    async getList() {
      try {
        this.loading = true;
        let params = {
 /*          ...(this.sort ? { sort: this.sort } : {}), */
      /*     filter: {
            ...(this.query ? { name: this.query } : {}),
          }, */
      /*     page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          }, */
   /*        include: "owner,reseller", */
        };

/*         if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.selectedReseller },
          };
        } */

        await this.$store.dispatch("organizationGroups/list", params);
        this.organizationGroups = this.$store.getters["organizationGroups/list"];
        await this.$store.dispatch("organizations/list", params);
        this.organizations = this.$store.getters["organizations/list"];
        /* this.total = this.$store.getters["organizationGroups/listTotal"]; */
        this.loading = false;
        
      } catch (error) {
        this.$notify({
           type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG")+error, 
          /* message:error, */
        });
      }
    },
    async attachOrganizations (rowId,organizationGroupId) {
      
    const selectedOrgIds = this.selectedOrganizations[rowId];
    console.log('Selected Organization IDs:', selectedOrgIds,'grp',organizationGroupId);
    try {
        const result = await this.$store.dispatch("organizationGroups/attach",{
          organizationId:       Number(selectedOrgIds[0]),
          organizationGroupId:  Number(organizationGroupId),
        });
        console.log('Organization group attached successfully:', result);
        // Optionally, perform further actions after successful attachment
      } catch (error) {
        console.error('Error attaching organization group:', error);
        // Handle error and notify user if needed
      }
  },
    deleteOrganizationGroups(organizationgroup) {
      this.$emit("onDeleteOrganizationGroup", organizationgroup);
    },

    viewOrganizationGroups(organizationgroup) {
      this.$emit("onViewOrganizationGroup", organizationgroup);
    },

    editOrganizationGroups(organizationgroup) {
      this.$emit("onEditOrganizationGroup", organizationgroup);
    },

    sortChange({ prop, order }) {
/*       if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      } */
      this.getList();
    },
  },
};
</script>
